import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein"] as const;

export const smbProtGrov = createFormula(
  dependencies,
  [],
  (analysis, { protein }, valid, blocked) => {
    if (!protein) {
      return { value: 0 };
    }
    const smbKoeff = (93.9 - 3130 / protein) / 100;
    return { value: protein * smbKoeff, blocked };
  },
);
