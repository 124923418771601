import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["empty", "dry", "wet"] as const;
export const dryWeight = createFormula(
  dependencies,
  [],
  (_analysis, { empty, dry, wet }, _valid, blocked) => {
    if (!empty || !dry || !wet || wet === empty) {
      return { value: 0 };
    }
    return {
      value: ((dry - empty) / (wet - empty)) * 100,
      blocked,
    };
  },
);

const dryWeightCorrDependencies = ["dryweight1"] as const;
export const dryWeightCorr = createFormula(
  [],
  dryWeightCorrDependencies,
  (analysis, { dryweight1 }, valid, blocked) => {
    if (!dryweight1) {
      return { value: 0, blocked };
    }
    const dryWeight2 = 0.95;

    let dryCorr = dryweight1 * dryWeight2;
    if (
      analysis.productMeta?.material?.toString().toLowerCase() === "ensilage"
    ) {
      dryCorr = 0.99 * dryCorr + 1;
    }
    return { value: dryCorr, blocked };
  },
);
