import { hasOwnProperty } from "../helpers/utils";
import type { Analysis, IAnalysis } from "../models/analysis";
type FormulaResult = {
  value: number;
  blocked?: boolean;
  minlimit?: boolean;
};

type RawFormula<T extends readonly string[]> = (
  analysis: IAnalysis,
  analyses: { [K in T[number]]: number | undefined },
  valid: boolean,
  blocked: boolean,
) => FormulaResult;

type DependecyLookup = (
  analysis: Analysis,
  analyses: readonly Analysis[],
) => IAnalysis[];

type DependencyMap = (
  analysis: IAnalysis,
  formulaName: string | undefined,
) => string | undefined;

type FormulaOptions = {
  optionalDependencies?: boolean;
  dependenciesLookup?: DependecyLookup;
  dependencyMap: DependencyMap;
};

export type Formula<
  T extends readonly string[] = readonly string[],
  U extends readonly string[] = readonly string[],
> = {
  formula: RawFormula<T | U>;
  formulaString?: string;
  dependencies: T;
  calculatedDependencies: U;
  formulaOptions: FormulaOptions;
};

export const fromFormulaName = (
  analyses: readonly Analysis[],
  formulaName: string,
) => {
  return analyses.find(
    (analysis) => analysis.meta && analysis.meta.formulaName === formulaName,
  );
};
const defaultFormulaOptions = (formulaOptions?: Partial<FormulaOptions>) => {
  return { dependencyMap: defaultDependencyMap, ...formulaOptions };
};
const defaultDependencyMap = (
  _analysis: IAnalysis,
  formulaName: string | undefined,
) => formulaName;

export const createFormula = <
  T extends readonly string[],
  U extends readonly string[],
>(
  dependencies: Formula<T, U>["dependencies"],
  calculatedDependencies: Formula<T, U>["calculatedDependencies"],
  formula: Formula<T, U>["formula"],
  formulaOptions?: Partial<FormulaOptions>,
): Formula<T, U> => ({
  dependencies,
  calculatedDependencies,
  formula,
  formulaOptions: defaultFormulaOptions(formulaOptions),
});

export const createStringFormula = <
  T extends readonly string[],
  U extends readonly string[],
>(
  dependencies: Formula<T, U>["dependencies"],
  calculatedDependencies: Formula<T, U>["calculatedDependencies"],
  formula: string,
  formulaOptions?: FormulaOptions,
): Formula<T> => ({
  dependencies,
  calculatedDependencies,
  formulaString: formula, //parse(formula).toString(),
  formula: (analysis, analyses, valid, blocked) => {
    const scope = {
      ...analyses,
      analysis: +(analysis.raw ?? 0),
      value: 0,
    };
    //parse(formula).evaluate(scope);
    return {
      value: scope.value,
      blocked,
    };
  },
  formulaOptions: defaultFormulaOptions(formulaOptions),
});

export function blocking(list: (undefined | Analysis | FormulaResult)[]) {
  return list.some((element) => {
    if (!element) {
      return true;
    }
    if (hasOwnProperty(element, "details")) {
      return !!(<Analysis>element).details?.blocked;
    } else {
      return !!(<FormulaResult>element).blocked;
    }
  });
}

export function validate(
  analysis: Analysis,
  analyses: readonly (Analysis | undefined)[],
) {
  if (analysis.raw == null) {
    return false;
  }
  return !analyses.find((a) => a?.raw == null);
}
