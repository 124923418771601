import type { IAnalysis } from "../models/analysis";
import type { AnalysisType } from "../models/analysisGroup";
import { aatGrov } from "./aatGrov";
import { type Formula, blocking, validate } from "./analysisFormulaUtils";
import { butyricacid } from "./butyricacid";
import { cab } from "./cab";
import { multiply } from "./common";
import { dryWeight, dryWeightCorr } from "./dryWeight";
import { drysubst } from "./drysubst";
import { drysubstSpa } from "./drysubstSpa";
import { elisaCtrl } from "./elisaCtrl";
import { epg } from "./epg";
import { gPerkgDrysubst } from "./gPerkgDrysubst";
import { metEnergyPerkg, nfeDrysubst } from "./metEnergyPerkg";
import { nh4n } from "./nh4n";
import { omd } from "./omd";
import { omsEnergyHest } from "./omsEnergyHorse";
import { omsEnergyIdis } from "./omsEnergyIdis";
import { ostertagia } from "./ostertagia";
import { pbvGrov } from "./pbvGrov";
import { pcr } from "./pcr";
import { percent } from "./percent";
import { protEnergyQuota } from "./protEnergyQuota";
import { quotaCaP } from "./quotaCaP";
import { scp } from "./scp";
import { smbCarbGrov } from "./smbCarbGrov";
import { smbProtGrov } from "./smbProtGrov";
import { spaceweight } from "./spaceweight";
import {
  fructan,
  fructose,
  glucose,
  sucrose,
  sucroseCorr,
  sugar,
} from "./sugar";

export type Formulas = keyof typeof formulas;

const formulas = {
  ostertagia,
  omd,
  elisaCtrl,
  metEnergyPerkg,
  nfeDrysubst,
  multiply,
  smbCarbGrov,
  aatGrov,
  pbvGrov,
  omsEnergyHest,
  omsEnergyIdis,
  smbProtGrov,
  protEnergyQuota,
  sugar,
  fructan,
  fructose,
  glucose,
  sucrose,
  pcr,
  cab,
  scp,
  nh4n,
  QCaP: quotaCaP,
  epg,
  percent,
  dryweight: dryWeight,
  dryweight2: dryWeightCorr,
  spaceweight,
  drysubst,
  drysubst_spa: drysubstSpa,
  "g/kg_drysubst": gPerkgDrysubst,
  butyricacid,
  sucroseCorr,
};

export const executeFormula = (
  formula: keyof typeof formulas,
  analysis: IAnalysis,
  analyses: readonly IAnalysis[],
  otherAnalyses: readonly IAnalysis[],
) => {
  const f = formulas[formula];
  if (!f) {
    return { value: analysis.raw };
  }
  const params = [...f.dependencies, ...f.calculatedDependencies]
    .map(
      (p) =>
        analyses.find((a) => a.meta?.formulaName === p) ??
        otherAnalyses.find((a) => a.meta?.formulaName === p),
    )
    .filter((a) => !!a)
    .concat(f.formulaOptions.dependenciesLookup?.(analysis, analyses) ?? []);
  const blocked = blocking(params);
  const valid = validate(analysis, params);
  const paramMap = Object.fromEntries(
    params.map((param) => [
      f.formulaOptions.dependencyMap(analysis, param.meta?.formulaName),
      f.calculatedDependencies.find((d) => d === param.meta?.formulaName)
        ? param.calc
        : param.raw,
    ]),
  );
  const result = f.formula(analysis, paramMap, valid, blocked);
  return result;
};

export const getFormula = (analysis: IAnalysis | AnalysisType | undefined) => {
  if (!analysis) {
    return undefined;
  }
  return formulas[analysis.meta?.formula as keyof typeof formulas];
};

export const lookupDependencies = (
  formula: Formula<readonly string[]>,
  visited = new Set<string>(),
): string[] => {
  if (!formula.dependencies) {
    return [];
  }
  return formula.dependencies.slice();
};

export const getFormulaString = (
  analysis: IAnalysis | AnalysisType | undefined,
) => {
  const formula = getFormula(analysis);
  if (!formula) {
    return undefined;
  }
  if (formula.formulaString) {
    return formula.formulaString;
  }
  return formula.formula ? `${formula.formula}` : undefined;
};
