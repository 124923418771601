import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["K", "Na", "cl", "S"] as const;

export const cab = createFormula(
  dependencies,
  [],
  (analysis, { K, Na, cl, S }) => {
    if (!K || !Na || !cl || !S) {
      return { value: 0 };
    }
    return {
      value:
        (K / 1000 / 39.1 +
          Na / 1000 / 23 -
          (cl / 35.5 + ((S / 1000) * 2) / 32)) *
        1000,
    };
  },
);
