import { createFormula } from "./analysisFormulaUtils";

const dependencies = [] as const;
export const pcr = createFormula(dependencies, [], (analysis) => {
  const lowerLimit = <number>analysis.meta?.lowerlimit;
  const breakpoint = <number>analysis.meta?.breakpoint;

  if (analysis.raw == null || !lowerLimit || !breakpoint) {
    return { value: 0 };
  }

  if (analysis.raw < lowerLimit) {
    return { value: 0, blocking: true };
  }

  return { value: analysis.raw >= breakpoint ? 0 : 1 };
});
