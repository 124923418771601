import { createFormula, fromFormulaName } from "./analysisFormulaUtils";

const dependencies = ["percentOf"] as const;

export const percent = createFormula(
  dependencies,
  [],
  (analysis, { percentOf }) => {
    let percentOfValue = analysis.percentOfValue;
    const blocked = false;
    if (!percentOfValue && percentOf) {
      percentOfValue = percentOf;
    }
    if (!percentOfValue) {
      return { value: 0, blocked };
    }
    if (percentOfValue <= 0) {
      return { value: 0, blocked };
    }
    if (analysis.raw == null) {
      return { value: 0, blocked };
    }
    const percent = +((analysis.raw / percentOfValue) * 100).toFixed();
    return { value: percent, blocked };
  },
  {
    optionalDependencies: true,
    dependenciesLookup: (analysis, analyses) => {
      const percentOf = analysis.percentOf
        ? fromFormulaName(analyses, analysis.percentOf)
        : undefined;
      return [percentOf].filter((a) => !!a);
    },
    dependencyMap: (analysis, formulaName) =>
      formulaName === analysis.percentOf ? "percentOf" : formulaName,
  },
);
