import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein"] as const;

export const scp = createFormula(dependencies, [], (analysis, { protein }) => {
  if (!protein || !analysis.raw) {
    return { value: 0 };
  }
  return { value: (analysis.raw / protein) * 1000 };
});
