import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["glucose", "fructose", "fructan", "sucrose"] as const;
const calculatedDependencies = ["sucroseCorr"] as const;

const sucroseCorrDependencies = [
  "sugar",
  "glucose",
  "fructose",
  "fructan",
  "sucrose",
] as const;

const sumPos = (arr: (number | undefined)[]) =>
  (<number[]>arr.filter((n) => n && n > 0)).reduce((p, c) => +p + +c, 0);

export const sucroseCorr = createFormula(
  sucroseCorrDependencies,
  [],
  (
    analysis,
    { sugar, glucose, fructose, fructan, sucrose },
    valid,
    blocked,
  ) => {
    if (
      sugar === undefined ||
      glucose === undefined ||
      fructose === undefined ||
      fructan === undefined ||
      sucrose === undefined
    ) {
      return { value: 0 };
    }
    return {
      value: (+glucose + +fructose + +fructan + +sucrose - +sugar) / 2,
      blocked,
    };
  },
  { optionalDependencies: true },
);
const sugarDependencies = ["sucroseCorr"] as const;
export const sugar = createFormula(
  [],
  sugarDependencies,
  (sugar, { sucroseCorr }, valid, blocked) => {
    if (sugar?.raw === undefined) {
      return { value: 0 };
    }

    return {
      value: +sugar.raw - (sucroseCorr ?? 0),
      blocked: false, //blocked || sucroseC.blocked,
    };
  },
);

export const glucose = createFormula(
  dependencies,
  calculatedDependencies,
  (glucose, { fructose, fructan, sucrose, sucroseCorr }, valid, blocked) => {
    if (glucose?.raw === undefined || sucroseCorr == null) {
      return { value: 0 };
    }
    const sum = sumPos([glucose.raw, fructose, fructan, sucrose]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +glucose.raw - sucroseCorr * (+glucose.raw / sum),
      blocked: blocked,
    };
  },
);

export const fructose = createFormula(
  dependencies,
  calculatedDependencies,
  (fructose, { sucroseCorr, glucose, fructan, sucrose }, valid, blocked) => {
    if (fructose?.raw === undefined || sucroseCorr == null) {
      return { value: 0 };
    }
    const sum = sumPos([glucose, fructose?.raw, fructan, sucrose]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +fructose.raw - sucroseCorr * (+fructose.raw / sum),
      blocked: blocked,
    };
  },
);

export const fructan = createFormula(
  dependencies,
  calculatedDependencies,
  (fructan, { sucroseCorr, glucose, fructose, sucrose }, valid, blocked) => {
    if (fructan?.raw === undefined || sucroseCorr == null) {
      return { value: 0 };
    }
    const sum = sumPos([glucose, fructose, fructan?.raw, sucrose]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +fructan.raw - sucroseCorr * (+fructan.raw / sum),
      blocked: blocked,
    };
  },
);

export const sucrose = createFormula(
  dependencies,
  calculatedDependencies,
  (sucrose, { sucroseCorr, glucose, fructose, fructan }, valid, blocked) => {
    if (sucrose?.raw === undefined || sucroseCorr == null) {
      return { value: 0 };
    }
    const sum = sumPos([glucose, fructose, fructan, sucrose?.raw]);
    if (sum === 0) {
      return { value: 0 };
    }
    return {
      value: +sucrose.raw - sucroseCorr * (+sucrose.raw / sum),
      blocked: blocked,
    };
  },
);
