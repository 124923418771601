import { createFormula } from "./analysisFormulaUtils";

export const omd = createFormula(
  ["vos"] as const,
  [],
  (analysis, { vos }, valid, blocked) => {
    if (vos === undefined) {
      return { value: 0 };
    }
    return { value: 0.9 * vos - 2.0, blocked };
  },
);
