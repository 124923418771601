import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["energy"] as const;
export const omsEnergyHest = createFormula(
  dependencies,
  [],
  (analysis, { energy }, valid, blocked) => {
    if (!energy) {
      return { value: 0 };
    }
    return { value: 1.12 * energy - 1.2, blocked };
  },
);
