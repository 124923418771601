import { createFormula } from "./analysisFormulaUtils";

const dependencies = [] as const;
export const epg = createFormula(dependencies, [], (analysis) => {
  if (analysis.raw == null) {
    return { value: 0, minlimit: false };
  }
  if (!analysis.multiple) {
    return { value: 0, minlimit: false };
  }
  if (analysis.raw > 0 && analysis.raw < 1) {
    return { value: 30, minlimit: true }; // "<30";
  }
  const adjusted = analysis.raw * analysis.multiple;
  if (analysis.meta?.strictValue) {
    return { value: adjusted, minlimit: false }; // "<" + adjusted;
  }
  let rounded = 0;
  if (adjusted < 99) {
    rounded = Math.floor(adjusted / 10) * 10;
  } else {
    rounded = (Math.round(adjusted / 50) / 2) * 100;
  }
  return { value: rounded, minlimit: false };
});
