import type { ApiStatus } from "../models/apiStatus";
import {
  type GeneratedReport,
  type RegistrationJournalGroup,
  type ResultJournalGroup,
  createJournalGroup,
} from "../models/journalGroup";
import type { Message } from "../models/message";
import type { CultivationGroup } from "../models/pocos/cultivationGroup";
import type { CultivationResult } from "../models/pocos/cultivationResult";
import type { LabnumberValidationResult } from "../models/pocos/labnumberValidationResult";
import { ApiBase } from "./apiBase";

type BlockSendResponse = {
  blockSend: boolean;
};

type ReportResponse = {
  report: GeneratedReport;
  csvName: string;
  canGenerate: boolean;
};

class JournalApi extends ApiBase {
  async generateReport(journalGroupId: number) {
    return this.get<ReportResponse>("journals/generate_report_group", {
      journalGroupId,
    });
  }

  async validateLabnumber(labnumber: number) {
    return this.get<boolean>("journals/validate_labnr", { labnr: labnumber });
  }

  async checkLabnumber(labnumber: number) {
    return this.get<LabnumberValidationResult>("journals/check_labnumber", {
      labnumber,
    });
  }

  async loadRegistration(labnumber: number) {
    return createJournalGroup(
      await this.get<RegistrationJournalGroup>("registration/load", {
        labnumber,
      }),
    );
  }

  async loadFromId(journalId: number) {
    return createJournalGroup(
      await this.get<RegistrationJournalGroup>("registration/load", {
        journal_id: journalId,
      }),
    );
  }

  async loadResult(journalId: number) {
    return createJournalGroup(
      await this.get<ResultJournalGroup>("journals/load", { journalId }),
    );
  }

  async loadTrikin() { //load all trikin journals
    const response = await this.get<ResultJournalGroup[]>("journals/load_trikin");
    return response.map(createJournalGroup);
  }

  async loadHunter() { // load all journals where the customer is a registered hunter
    const response = await this.get<ResultJournalGroup[]>("journals/load_hunter");
    return response.map(createJournalGroup);
  }

  async remove(journalGroupId: number) {
    return this.post<boolean>("journals/remove_group", { journalGroupId });
  }

  async removeSingle(journalId: number) {
    return this.post<boolean>("journals/remove_single", { journalId });
  }

  async checkSetCultivation(
    labnumber: number,
    cultivationType: "odling" | "lungmask" | "pcr" | "elisa",
  ) {
    return this.get<CultivationResult>("analysis/required", {
      labnr: labnumber,
      check_type: "to_start",
      cultivation_type: cultivationType,
    });
  }

  async checkFetchCultivation(
    labnumber: number,
    cultivationType: "odling" | "lungmask",
  ) {
    return this.get<CultivationResult>("analysis/required", {
      labnr: labnumber,
      check_type: "to_read",
      cultivation_type: cultivationType,
    });
  }

  async forceStartCultivation(labnumber: number) {
    return this.post<CultivationResult>("analysis/force_start", { labnumber });
  }

  async refuseCultivation(labnumber: number, reason: string) {
    return this.post<CultivationResult>("analysis/refuse", {
      labnumber,
      reason,
    });
  }

  async ungroupCultivation(group: CultivationGroup) {
    return this.post<CultivationResult>("analysis/ungroup", {
      labnumber: group.labnr,
      groupnr: group.group,
      analysis_group_type: 502,
    });
  }

  async lockCultivationGroup(labnumber: number, groupnr: number) {
    return this.post<CultivationResult>("analysis/close_group", {
      labnumber: labnumber,
      groupnr: groupnr,
      analysis_group_type: 502,
    });
  }

  async sendApi(journalGroupId: number, service: string) {
    return this.post<{ status: ApiStatus }>("journals/send_api", {
      journalGroupId,
      service,
    });
  }

  async apiStatus(journalGroupId: number) {
    return this.get<{ status: ApiStatus }>("journals/api_status", {
      journalGroupId,
    });
  }

  async stopSending(journalGroupId: number) {
    return this.post<BlockSendResponse>("journals/stop_sending", {
      journalGroupId,
    });
  }

  async resumeSending(journalGroupId: number) {
    return this.post<BlockSendResponse>("journals/resume_sending", {
      journalGroupId,
    });
  }

  async report(journalGroupId: number) {
    return this.get<ReportResponse>("journals/report", { journalGroupId });
  }

  async messages(journalGroupId: number) {
    return this.get<{
      internalMessages: Message[];
      customerMessages: Message[];
    }>("journals/messages", { journalGroupId });
  }
}

export default new JournalApi();
