import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { hasPermission } from "../helpers/authHelpers";

export const useCurrentUser = () => {
  const { currentUser } = useContext(UserContext);
  return currentUser;
};

export const useAuthenticated = () => {
  const authenticated = !!useCurrentUser();
  return authenticated;
};

export const usePermission = (permission: string) => {
  const permissions = usePermissions();
  return hasPermission(permission, permissions);
};

export const useHasPermission = () => {
  const permissions = usePermissions();
  return (permission: string | undefined) =>
    hasPermission(permission, permissions);
};

export const useHasPermissions = () => {
  const userPermissions = usePermissions();
  return (permissions: string[]) =>
    permissions.every((permission) =>
      hasPermission(permission, userPermissions),
    );
};

export const usePermissions = () => {
  return useCurrentUser()?.permissions ?? {};
};
