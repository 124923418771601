import { Button } from "@mui/material";
import * as React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router";

interface IErrorBoundaryState {
  hasError: boolean;
  faultReason?: string;
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  IErrorBoundaryState
> {
  state: IErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, faultReason: error.name };
  }

  render() {
    if (this.state.hasError) {
      if (this.state.faultReason === "ChunkLoadError") {
        return (
          <div style={{ width: "100%" }}>
            Hoppsan, den här sidan gick inte att ladda. Troligtvis för att det
            finns en uppdaterad version av den. Klicka på knappen nedan för att
            ladda om sidan.
            <Button onClick={() => window.location.reload()}>
              Försök igen
            </Button>
          </div>
        );
      }
      return <div>Något gick fel!</div>;
    }
    return this.props.children;
  }
}

export const ErrorElement = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
};
