import { createFormula, createStringFormula } from "./analysisFormulaUtils";

const dependencies = ["pos1", "pos2", "neg1", "neg2"] as const;
const operations = [
  "pos = (pos1 + pos2) / 2.0",
  "neg = (neg1 + neg2) / 2.0",
  "value = (analysis - neg) / (pos - neg)",
];
export const ostertagiaS = createStringFormula(
  dependencies,
  [],
  operations.join("\n"),
);

export const ostertagia = createFormula(
  dependencies,
  [],
  (analysis, { pos1, pos2, neg1, neg2 }, valid, blocked) => {
    if (!pos1 || !pos2 || !neg1 || !neg2 || !analysis.raw) {
      return { value: 0, blocked };
    }

    const pos = (+pos1 + +pos2) / 2.0;
    const neg = (+neg1 + +neg2) / 2.0;
    const value = (+analysis.raw - neg) / (pos - neg);
    return { value, blocked };
  },
);
