import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein"] as const;

const calculatedDependencies = ["smbCarbGrov"] as const;
export const pbvGrov = createFormula(
  dependencies,
  calculatedDependencies,
  (analysis, { protein, smbCarbGrov }, valid, blocked) => {
    if (!protein || !smbCarbGrov) {
      return { value: 0 };
    }
    const EPD = 0.8;
    const B49 = 0.179;
    return {
      value: protein * EPD - +smbCarbGrov * B49,
      blocked,
    };
  },
);
