import { smbCarbConstants } from "../helpers/analysisFormulaConstants";
import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["energy", "protein"] as const;
export const smbCarbGrov = createFormula(
  dependencies,
  [],
  (analysis, { energy, protein }, valid, blocked) => {
    if (!energy || !protein || !analysis.productMeta?.material) {
      return { value: 0 };
    }
    const magicConstants = smbCarbConstants(
      analysis.productMeta.material.toString(),
    );
    const smbKoeff = (magicConstants.a + magicConstants.b * energy) / 100;
    const carbs = magicConstants.c - protein * 1.46;
    return { value: smbKoeff * carbs, blocked };
  },
);
