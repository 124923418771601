import type { ExtraField } from "./extraField";

interface ExtraMail {
  receivers: string[];
  condition: string[];
  value: unknown;
  lookup: string;
  type: "csv" | "pdf" | undefined;
  onlyFinal: boolean;
}
export interface IProductFamily {
  id: number;
  typeName: string;
  name: string;
  designation: string;
  idType: string;
  unique: boolean;
  metaFields: {
    fields?: { [key: string]: ExtraField };
    showVeterinarian?: boolean;
    hideAnimalRegistration?: boolean;
    showHunter?: boolean; // only moves ID-field from animalRegistration to analysisRegistration (if true)
    extraFields?: { [key: string]: ExtraField };
    extraMail?: ExtraMail[];
    followup?: boolean;
    stepOverrides?: {
      analysis?: string;
      customer?: string;
      animal?: string;
      veterinarian?: string;
    };
  };
}

export function extraProductFamilyFields(
  productFamily: IProductFamily | undefined,
) {
  return productFamily?.metaFields?.fields || {};
}

export function readProductFamilyMeta<
  T extends keyof IProductFamily["metaFields"],
>(productFamily: IProductFamily, key: T): IProductFamily["metaFields"][T] {
  return productFamily.metaFields?.[key];
}
