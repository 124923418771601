import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["omsEnergyHest", "smbProtGrov"] as const;
export const protEnergyQuota = createFormula(
  [],
  dependencies,
  (analysis, { omsEnergyHest, smbProtGrov }, valid, blocked) => {
    if (!omsEnergyHest || !smbProtGrov) {
      return { value: 0 };
    }
    return { value: +smbProtGrov / +omsEnergyHest, blocked };
  },
);
