import ActionCable from "actioncable";
import {
  createContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from "react";
import { useCurrentUser } from "../hooks/authHooks";

type CableContextType = {
  cable: ReturnType<typeof ActionCable.createConsumer>;
};

export const CableContext = createContext<CableContextType | undefined>(
  undefined,
);

export const CableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useCurrentUser();
  const [cable, setCable] =
    useState<ReturnType<typeof ActionCable.createConsumer>>();

  useEffect(() => {
    const newCable = ActionCable.createConsumer(
      `${import.meta.env.VITE_CABLE_URL}?userId=${user?.id}`,
    );
    setCable(newCable);
    return () => {
      newCable.disconnect();
    };
  }, [user?.id]);

  return (
    <CableContext.Provider value={{ cable: cable! }}>
      {children}
    </CableContext.Provider>
  );
};
