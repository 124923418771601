import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["moisture"] as const;
export const drysubstSpa = createFormula(
  dependencies,
  [],
  (analysis, { moisture }, valid, blocked) => {
    if (!moisture) {
      return { value: 0, blocked };
    }
    return { value: 100 - moisture, blocked };
  },
);
