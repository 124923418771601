import { digestibility } from "../helpers/analysisFormulaConstants";
import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein", "fat", "fibre", "ash"] as const;
export const nfeDrysubst = createFormula(
  [],
  dependencies,
  (analysis, { fat, protein, ash, fibre }) => {
    if (!fat || !protein || !ash || !fibre) {
      return { value: 0 };
    }
    return {
      value: 1000 - +fat - +protein - +ash - +fibre,
    };
  },
);

export const metEnergyPerkg = createFormula(
  [],
  ["protein", "fat", "fibre", "ash", "nfeDrysubst"] as const,
  (analysis, { protein, fat, fibre, nfeDrysubst }, valid, blocked) => {
    if (
      !protein ||
      !fat ||
      !fibre ||
      !nfeDrysubst ||
      !analysis.productMeta?.material
    ) {
      return { value: 0 };
    }

    const digestibilityMap = digestibility(
      analysis.productMeta.material?.toString(),
    );
    return {
      value:
        (+protein * (digestibilityMap.protein / 100) * 18.9 +
          +fat * (digestibilityMap.fat / 100) * 34.8 +
          +fibre * (digestibilityMap.fibre / 100) * 12.2 +
          nfeDrysubst * (digestibilityMap.nfe / 100) * 15.5) /
        1000,
      blocked,
    };
  },
);
