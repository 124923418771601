import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["protein"] as const;
const calculatedDependencies = ["smbCarbGrov"] as const;
export const aatGrov = createFormula(
  dependencies,
  calculatedDependencies,
  (analysis, { protein, smbCarbGrov }, valid, blocked) => {
    if (!protein || !smbCarbGrov) {
      return { value: 0 };
    }
    const EPD = 0.8;
    const a = 0.65;
    const b = 0.82;
    const c = 0.179;
    const d = 0.7;
    const e = 0.85;
    return {
      value: protein * (1 - EPD) * a * b + +smbCarbGrov * c * d * e,
      blocked,
    };
  },
);
