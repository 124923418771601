import { createFormula } from "./analysisFormulaUtils";

const dependencies = ["energy"] as const;
export const omsEnergyIdis = createFormula(
  dependencies,
  [],
  (analysis, { energy }, valid, blocked) => {
    if (!energy) {
      return { value: 0 };
    }
    return { value: +energy || 0, blocked };
  },
);
