import { createFormula, fromFormulaName } from "./analysisFormulaUtils";

const dependencies = ["vattenhalt", "percentStuff"] as const;

export const drysubst = createFormula(
  dependencies,
  [],
  (analysis, { vattenhalt, percentStuff }, valid, blocked) => {
    if (!vattenhalt || !percentStuff || vattenhalt === percentStuff) {
      return { value: 0, blocked };
    }
    const dry = 100 - vattenhalt;
    return {
      value: (percentStuff / dry) * 100,
      blocked,
    };
  },
  {
    dependenciesLookup: (analysis, analyses) => {
      const percentStuff = fromFormulaName(
        analyses,
        <string>analysis.meta?.base_formula_name,
      );
      return [percentStuff].filter((a) => !!a);
    },
  },
);
